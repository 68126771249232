import type { QueryResult, TypedDocumentNode } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import { useSuspenseQuery } from '@apollo/client/react/hooks';
import type { UseSuspenseQueryResult } from '@apollo/client/react/hooks/useSuspenseQuery';
import type { QueryHookOptions, SuspenseQueryHookOptions } from '@apollo/client/react/types/types';
import { getOperationName } from '@apollo/client/utilities';
import type { ApolloQueryContext } from '@aurora/shared-types/apollo/tracing';
import { filterNullInClauseVariables } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type { DocumentNode } from 'graphql';
import { useContext } from 'react';
import { getParentFrameId } from './context/AnalyticsParentFrames/useFrameEnd';
import SwitchUserContext from './context/SwitchUserContext/SwitchUserContext';
import useTracing from './useTracing';

export type ExtendedQueryResult<TData, TVariables> = QueryResult<TData, TVariables> & {
  /**
   * Ready signifies if data from the query is available.
   */
  ready: boolean;
};

/**
 * Wrapper around `useSuspenseQuery` that adds tracking info to the `context` property.
 *
 * @param module the module of the component
 * @param query the query
 * @param options the query options
 * @param bypassSwitchUser whether or not to bypass switched user
 *
 * @author Adam Ayres
 */
export default function useSuspenseQueryWithTracing<
  TData = unknown,
  TVariables = OperationVariables
>(
  module: NodeModule | string,
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SuspenseQueryHookOptions<TData, TVariables>,
  bypassSwitchUser?: boolean
): UseSuspenseQueryResult<TData, TVariables> {
  const operationName: string = getOperationName(query);
  const tracingContext: ApolloQueryContext = useTracing<
    TVariables,
    QueryHookOptions<TData, TVariables>
  >(module, operationName, options);
  const { switchUserId } = useContext(SwitchUserContext);
  const parentFrameId = getParentFrameId();

  const context: ApolloQueryContext = {
    ...tracingContext,
    parentFrameId,
    switchUserId: bypassSwitchUser ? undefined : switchUserId
  };
  if (options?.variables) {
    filterNullInClauseVariables(options.variables as Record<string, unknown>);
  }
  return useSuspenseQuery<TData, TVariables>(query, { ...options, context });
}
